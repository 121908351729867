// Pulled styling from
// https://github.com/ant-design/ant-design/blob/master/components/select/style/index.less

$select-prefix-cls: '#{$ant-prefix}-select';

$control-padding-horizontal: 12px;
$control-padding-horizontal-sm: 8px;
$font-size-sm: 12px;
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$input-disabled-bg: gray;
$background-color-base: lighten($grey120, 30%);
$font-size-lg: 16px;
$input-height-lg: 40px;
$input-height-sm: 24px;
$input-placeholder-color: lightgrey;
$tag-default-color: $cox-blue;
$tag-default-bg: hsv(0, 0, 98%);
$border-radius-sm: 2px;
$item-hover-bg: $grey120;
$background-color-light: hsv(0, 0, 98%);
$item-active-bg: lighten($grey120, 30%);
$input-height-base: 32px;

.#{$select-prefix-cls} {
  @include reset-component;

  display: inline-block;
  position: relative;
  color: $gray800;

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul > li > a {
    padding: 0;
    background-color: $component-background;
  }

  // arrow
  &-arrow {
    @include iconfont-mixin();

    position: absolute;
    top: 60%;
    right: $control-padding-horizontal - 1px;
    line-height: 1;
    margin-top: -$font-size-sm / 2;
    transform-origin: 50% 50%;
    color: $gray800;
    font-size: $font-size-sm;

    * {
      display: none;
    }

    &::before {
      display: inline-block;
      width: 8px;
      height: 8px;
      user-select: none;
      border-color: $gray800;
      border-style: solid;
      border-width: 2px 2px 0 0;
      border-radius: 0 1px 0 0;
      content: '';
      position: relative;
      top: -5px;
      transform: rotate(135deg);
    }
  }

  &-selection {
    outline: none;
    user-select: none;
    box-sizing: border-box;
    display: block;
    // border-radius: $border-radius-base;
    // border: $border-width-base $border-style-base $border-color-base;
    // strange align fix for chrome but works
    // https://gw.alipayobjects.com/zos/rmsportal/VFTfKXJuogBAXcvfAUWJ.gif
    border-top-width: $border-width-base + 0.02px;

    &:hover {
      @include hover;
    }

    .#{$select-prefix-cls}-focused &,
    &:focus,
    &:active {
      @include active;
    }

    &__clear {
      @include selection__clear();
    }

    &:hover &__clear {
      opacity: 1;
    }

    &-selected-value {
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      padding-right: 20px;
      line-height: 22px
    }
  }

  &-disabled {
    color: $disabled-color;
  }

  &-disabled &-selection {
    background: $input-disabled-bg;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      border-color: $border-color-base;
      box-shadow: none;
    }

    &__clear {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
  }

  &-disabled &-selection--multiple &-selection__choice {
    background: $background-color-base;
    color: #aaa;
    padding-right: 10px;

    &__remove {
      display: none;
    }
  }

  &-selection--single {
    height: $spacing-l;
    position: relative;
    cursor: pointer;
  }

  &-selection__rendered {
    display: block;
    margin-left: $control-padding-horizontal - 1px;
    margin-right: $control-padding-horizontal - 1px;
    position: relative;
    line-height: $input-height-base - 2px;
    // https://github.com/ant-design/ant-design/issues/3481#issuecomment-254721026
    &::after {
      content: '.';
      visibility: hidden;
      pointer-events: none;
      display: inline-block;
      width: 0;
    }
  }

  &-lg {
    font-size: $font-size-lg;

    .#{$select-prefix-cls}-selection--single {
      height: $input-height-lg;
    }

    .#{$select-prefix-cls}-selection__rendered {
      line-height: $input-height-lg - 2px;
    }

    .#{$select-prefix-cls}-selection--multiple {
      min-height: $input-height-lg;

      .#{$select-prefix-cls}-selection__rendered {
        li {
          height: $input-height-lg - 8px;
          line-height: $input-height-lg - 8px;
        }
      }

      .#{$select-prefix-cls}-selection__clear {
        top: $input-height-lg / 2;
      }
    }
  }

  &-sm {
    .#{$select-prefix-cls}-selection--single {
      height: $input-height-sm;
    }

    .#{$select-prefix-cls}-selection__rendered {
      line-height: $input-height-sm - 2px;
      margin: 0 $control-padding-horizontal-sm - 1px;
    }

    .#{$select-prefix-cls}-selection--multiple {
      min-height: $input-height-sm;

      .#{$select-prefix-cls}-selection__rendered {
        li {
          height: $input-height-sm - 8px;
          line-height: $input-height-sm - 10px;
        }
      }

      .#{$select-prefix-cls}-selection__clear {
        top: $input-height-sm / 2;
      }
    }

    .#{$select-prefix-cls}-selection__clear,
    .#{$select-prefix-cls}-arrow {
      right: $control-padding-horizontal-sm;
    }
  }

  &-disabled &-selection__choice__remove {
    color: $disabled-color;
    cursor: default;

    &:hover {
      color: $disabled-color;
    }
  }

  &-search__field__wrap {
    display: inline-block;
    position: relative;
  }

  &-selection__placeholder,
  &-search__field__placeholder {
    // for TreeSelect compatibility
    position: absolute;
    top: 50%;
    left: 0;
    right: 9px;
    color: $input-placeholder-color;
    line-height: 20px;
    height: 20px;
    max-width: 100%;
    margin-top: -10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  &-search__field__placeholder {
    left: $control-padding-horizontal;
  }

  &-search__field__mirror {
    position: absolute;
    top: 0;
    left: -9999px;
    white-space: pre;
    pointer-events: none;
  }

  &-search--inline {
    position: absolute;
    height: 100%;
    width: 100%;

    .#{$select-prefix-cls}-search__field__wrap {
      width: 100%;
      height: 100%;
    }

    .#{$select-prefix-cls}-search__field {
      border-width: 0;
      font-size: 100%;
      height: 100%;
      width: 100%;
      background: transparent;
      outline: 0;
      border-radius: $border-radius-base;
      line-height: 1;
    }

    > i {
      float: right;
    }
  }

  &-allow-clear &-selection--multiple &-selection__rendered {
    margin-right: 20px; // In case that clear button will overlap content
  }

  &-open {
    .#{$select-prefix-cls}-arrow::before {
      top: 0;
      transform: rotate(-45deg);
    }

    .#{$select-prefix-cls}-selection {
      @include active();
    }
  }
}

.#{$select-prefix-cls}-dropdown {
  @include reset-component;

  &-hidden {
    display: none;
  }

  background-color: $component-background;
  box-shadow: $box-shadow-base;
  border-radius: $border-radius-base;
  box-sizing: border-box;
  z-index: ($zindex-dropdown - 10);
  left: -9999px;
  top: -9999px;
  position: absolute;
  outline: none;
  font-size: $font-size-base;

  ul {
    padding: $spacing-xs 0 $spacing-xs;
  }

  &-menu {
    outline: none;
    margin-bottom: 0;
    padding-left: 0; // Override default ul/ol
    list-style: none;
    max-height: 250px;
    overflow: auto;
    border-radius: $border-radius-base;
    border: 1px solid $border-color-default;

    &-item {
      position: relative;
      display: block;
      padding: 5px $control-padding-horizontal;
      line-height: 22px;
      font-weight: normal;
      color: $text-color;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: $item-hover-bg;
      }

      &-disabled {
        color: $disabled-color;
        cursor: not-allowed;

        &:hover {
          color: $disabled-color;
          background-color: $component-background;
          cursor: not-allowed;
        }
      }

      &-selected {
        &,
        &:hover {
          background-color: $background-color-light;
          font-weight: 600;
          color: $text-color;
        }
      }

      &-active {
        background-color: $item-active-bg;
      }

      &-divider {
        height: 1px;
        margin: 1px 0;
        overflow: hidden;
        background-color: $border-color-split;
        line-height: 0;
      }
    }
  }

  &-container-open,
  &-open {
    .#{$select-prefix-cls}-dropdown {
      display: block;
    }
  }
}
