@mixin clearfix() {
  zoom: 1;

  &::before,
  &::after {
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0;
  }
}

@mixin square($size) {
  @include size($size, $size);
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin reset-component() {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin iconfont-mixin() {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    display: block;
    // font-family: 'anticon' !important;
  }
}

@mixin iconfont-font($content) {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: $content;
}

@mixin hover() {
  border-color: $cox-blue;
}

@mixin active() {
  border-color: $cox-blue;
  outline: 0;
  box-shadow: 0 0 $cox-blue;
}

@mixin selection__clear() {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  opacity: 0;
  position: absolute;
  right: $control-padding-horizontal - 1px;
  z-index: 1;
  background: $component-background;
  top: 50%;
  font-size: $font-size-sm;
  color: $disabled-color;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;

  &::before {
    display: block;

    @include iconfont-font('>');
  }

  &:hover {
    color: $text-color-secondary;
  }
}
