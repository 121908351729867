@import '../../theme/src/base/_cx-bootstrap-variables.scss';

$ant-prefix: ant;
$table-header-icon-color: $grey670;
$table-prefix-cls: '#{$ant-prefix}-table';
$iconfont-css-prefix: anticon;

$component-background: #fff;
$text-color-secondary: $grey670;
$table-header-bg: $table-bg;
$heading-color: $grey670;
$border-width-base: 1px;
$border-style-base: solid;
$border-color-split: $border-color-default;
$primary-color: $cox-blue;
$primary-2: $cox-blue;
$table-row-hover-bg: $grey120;
$table-expanded-row-hover-bg: $grey120;
$table-selected-row-bg: lighten($cox-blue, 50%);
$table-padding-vertical: 8px;
$table-padding-horizontal: 16px;
$table-header-sort-bg: $grey120;
$box-shadow-base: $box-shadow-deep;
$link-active-color: $link-color;
$shadow-color: $grey120;

@import './_table-mixins.scss';
@import './_table-body.scss';
@import './_table-spinner.scss';
@import './_table-pagination.scss';
@import './_table-select.scss';
@import './_table-radio-buttons.scss';

/**
  STEPS TO CONVERT ANT DESIGN TABLE STYLING TO CX STYLING

  Convert the referenced less to sass and used less2scss.awk5.com.
  Fix the errors that were created by the conversion.
  Find all the referenced less global styles, other component less files (such as Spin, Select, Pagination, and Table),
  and mixins and convert them to SASS and add them.
  Use theme styling variables within these converted files so that theme will work on the table.
  Change some of the styling to match the mock-ups that the UX team made such as the chevron for
  expanding rows and sorting columns icons, the expanding rows to not have a line dividing the expanded row from the original row,
  and modifying the styling of the pagiation.
*/
