@import '../../theme/src/base/_cx-bootstrap-variables.scss';

.cx-alert {
  border-radius: $border-radius-base;
  background-color: $white;
  box-shadow: $box-shadow-shallow;
  border: 1px solid $border-color-default;
  display: flex;
  margin-bottom: $spacing-m;
}

.cx-alert__content {
  align-self: center;
  padding: $spacing-xs $spacing-xs $spacing-xs 0;
  flex-grow: 1;
}

.cx-alert__icon {
  padding: ($spacing-s - 1) $spacing-s;
  margin-right: $spacing-s;
  border-radius: $spacing-xs 0 0 $spacing-xs;
  color: $white;

  & .svgicon {
    display: block !important; // overwriting generated inline styling
  }
}

.cx-alert__dismiss {
  cursor: pointer;
  color: $grey670;
  margin: $spacing-s $spacing-s 0 0;
  min-width: $icon-size-m;
  max-height: $icon-size-m;
}

.cx-alert__dismiss:hover .icon-close {
  opacity: $opacity-hover;
}

.cx-alert__warning .cx-alert__icon {
  background-color: $orange-ext2;
  color: $text-color;
}

.cx-alert__danger .cx-alert__icon {
  background-color: $brand-danger;
}

.cx-alert__info .cx-alert__icon {
  background-color: $brand-info;
}

.cx-alert__success .cx-alert__icon {
  background-color: $brand-success;
}
