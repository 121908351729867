@import '../../theme/src/base/_cx-bootstrap-variables.scss';

.#{$bsPrefix}badge {
  font-weight: 400;
  padding: $spacing-xs $spacing-s;
  color: $text-color;

  &.count {
    background-color: $red;
    color: $white;
  }

  &.red {
    background-color: $red200;
  }

  &.orange {
    background-color: $orange200;
  }

  &.yellow {
    background-color: $yellow200;
  }

  &.green {
    background-color: $green200;
  }

  &.blue {
    background-color: $blue200;
  }

  &.purple {
    background-color: $purple200;
  }
}
