@import '../../../theme/src/base/_cx-bootstrap-variables.scss';

$breadcrumb-height: 3rem;

.modal-breadcrumb {
  &.#{$bsPrefix}breadcrumb {
    margin: 0;
    padding: 0;
    background: none transparent;
  }

  &__back a {
    display: block;
    width: $breadcrumb-height;
    height: $breadcrumb-height;
    margin: 0;
    padding: 0;
    line-height: $breadcrumb-height;
    text-align: center;
  }

  &__back + .modal-breadcrumb__item::before {
    display: none;
  }

  &__item.disabled a {
    color: $text-muted;
  }
}
