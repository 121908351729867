// Pulled styling from
// https://github.com/ant-design/ant-design/blob/master/components/spin/style/index.less

$spin-prefix-cls: #{'#{$ant-prefix}-spin'};

.#{$spin-prefix-cls}-nested-loading {
  position: relative;
}

.#{$spin-prefix-cls} {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: 36%;

  &-spinning {
    opacity: 1;
  }

  &-blur {
    overflow: hidden;
    opacity: 0.7;
    filter: blur(0.5px);

    /* autoprefixer: off */
    filter: #{'progid\:DXImageTransform\.Microsoft\.Blur(PixelRadius\=1, MakeShadow\=false)'};
    // workround for a strange style bug in safari:
    // https://github.com/ant-design/ant-design/issues/4622
    // have no clue why this works
    transform: translateZ(0);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #fff;
      opacity: 0.3;
      transition: all 3s;
      z-index: 10;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .#{$spin-prefix-cls}-blur {
    background: $component-background;
    opacity: 0.5;
  }
}
