@import '../../theme/src/base/_cx-bootstrap-variables.scss';

$breadcrumb-height: 3rem;

.modal-full {
  .#{$bsPrefix}modal-dialog {
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .#{$bsPrefix}modal-content {
    display: flex;
    flex-direction: column;
    border: 0 none;
    border-radius: 0;
    outline: 0 none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .#{$bsPrefix}modal-header {
      flex: 0 0 auto;
      min-height: 3rem;
    }

    .#{$bsPrefix}modal-body {
      flex: 1 1 auto;
      overflow-y: scroll;
    }

    .#{$bsPrefix}modal-footer {
      flex: 0 0 auto;
    }
  }

  &.modal-full-breadcrumb {
    .#{$bsPrefix}modal-header {
      margin: 0;
      padding: 0;
      min-height: $breadcrumb-height;

      .close {
        width: $breadcrumb-height;
        height: $breadcrumb-height;
        margin: 0;
        padding: 0;
        line-height: $breadcrumb-height;
        text-align: center;
      }
    }
  }
}
