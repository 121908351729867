@import '../../../theme/src/base/_cx-bootstrap-variables.scss';

.#{$bsPrefix}input-group {
  &.input-group--prefix .#{$bsPrefix}input-group-addon.addon-prefix,
  &.input-group--suffix .#{$bsPrefix}input-group-addon.addon-suffix {
    background-color: $gray300;
    padding-right: $spacing-xs;
    padding-left: $spacing-xs;
  }

  & input.#{$bsPrefix}form-control + .addon-wrapper {
    border-left: 0px;
  }

  & .#{$bsPrefix}input-group-btn {
    & + .#{$bsPrefix}input-group-btn button.#{$bsPrefix}btn {
      margin-left: -1px;
    }

    & > button.#{$bsPrefix}btn.#{$bsPrefix}btn-default,
    & > .#{$bsPrefix}dropdown > button.#{$bsPrefix}btn.#{$bsPrefix}btn-default {
      border-radius: 0;
      border-color: $input-border;

      &.#{$bsPrefix}btn-primary {
        border-color: $btn-primary-border;
      }
    }

    &.input-group-dropdown-button {
      z-index: 3;
    }

    &.addon-icon > button {
      padding: 3px $spacing-s;
    }
  }

  &.disabled .#{$bsPrefix}input-group-btn > button,
  &.disabled .#{$bsPrefix}input-group-addon > .addon-svg-wrapper {
    opacity: 0.5;
    cursor: not-allowed;
  }

  & .#{$bsPrefix}input-group-addon.addon-icon {
    padding: 0px;
    & .addon-svg-wrapper {
      padding: 0 $spacing-xs;
    }
  }

  & .#{$bsPrefix}input-group-btn.addon-icon > button,
  & .#{$bsPrefix}input-group-addon.addon-icon > .addon-svg-wrapper {
    color: $grey530;
  }

  & .#{$bsPrefix}input-group-btn.addon-icon > button svg.svgicon,
  &
    .#{$bsPrefix}input-group-addon.addon-icon
    > .addon-svg-wrapper
    > svg.svgicon {
    display: block;
    margin: auto;
  }

  .#{$bsPrefix}input-group-btn:last-child > .#{$bsPrefix}btn,
  .#{$bsPrefix}input-group-btn.input-group-dropdown-btn,
  .#{$bsPrefix}input-group-btn:last-child
    > .btn-group
    &
    .#{$bsPrefix}input-group-btn,
  & .#{$bsPrefix}input-group-addon {
    &:last-child,
    &:last-child button.#{$bsPrefix}btn,
    &:last-child > .#{$bsPrefix}dropdown > button.#{$bsPrefix}btn {
      border-top-right-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .#{$bsPrefix}input-group-btn:first-child > .#{$bsPrefix}btn,
  .#{$bsPrefix}input-group-btn.input-group-dropdown-btn,
  .#{$bsPrefix}input-group-btn:first-child
    > .btn-group
    &
    .#{$bsPrefix}input-group-btn,
  & .#{$bsPrefix}input-group-addon {
    &:first-child,
    &:first-child button.#{$bsPrefix}btn {
      border-top-left-radius: $border-radius-base;
      border-bottom-left-radius: $border-radius-base;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.has-error {
  & .#{$bsPrefix}input-group {
    & .#{$bsPrefix}input-group-addon.addon-icon {
      background-color: $brand-danger;
    }

    & .#{$bsPrefix}input-group-addon.addon-icon .addon-svg-wrapper,
    & .#{$bsPrefix}input-group-btn > button.#{$bsPrefix}btn,
    &
      .#{$bsPrefix}input-group-btn
      > .#{$bsPrefix}dropdown
      > button.#{$bsPrefix}btn,
    & .#{$bsPrefix}input-group-btn.addon-icon {
      border-color: $brand-danger;
      background-color: $brand-danger;
      color: $white;

      &:first-child {
        border-bottom-left-radius: $border-radius-base;
        border-top-left-radius: $border-radius-base;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;
      }
    }

    & .#{$bsPrefix}input-group-btn {
      & > button.#{$bsPrefix}btn,
      & > .#{$bsPrefix}dropdown > button.#{$bsPrefix}btn {
        border-color: $brand-danger;

        & .addon-svg-wrapper,
        & svg.svgicon {
          color: $white;
        }
        & .#{$bsPrefix}caret {
          border-color: $white;
        }
      }
    }
  }
}
