// Pulled styling from
// https://github.com/ant-design/ant-design/blob/master/components/table/style/index.less

.#{$table-prefix-cls}-wrapper {
  @include clearfix;

  & .text-right {
    text-align: right !important;
  }

  & .text-center {
    text-align: center !important;
  }
}

.#{$table-prefix-cls} {
  @include reset-component;

  overflow: hidden;
  position: relative;
  border-radius: $border-radius-base $border-radius-base 0 0;

  &-body {
    transition: opacity 0.3s;
  }

  table {
    width: 100%;
    text-align: left;
    overflow: hidden;
    border-collapse: separate;
  }

  &-thead > tr > th {
    background: transparent;
    transition: background 0.3s ease;
    text-align: left;
    color: $heading-color;
    font-weight: 400;
    font-size: $font-size-small;
    line-height: 16px;

    &[colspan] {
      text-align: center;
      border-bottom: 0;
    }

    .#{$iconfont-css-prefix}-filter,
    .#{$table-prefix-cls}-filter-icon {
      position: relative;
      margin-left: 8px;
      font-size: $font-size-base;
      cursor: pointer;
      color: $table-header-icon-color;
      transition: all 0.3s;
      width: 14px;
      font-weight: normal;
      vertical-align: text-bottom;

      &:hover {
        color: $text-color;
      }
    }

    .#{$table-prefix-cls}-column-sorter + .#{$iconfont-css-prefix}-filter {
      margin-left: 4px;
    }

    .#{$table-prefix-cls}-filter-selected.#{$iconfont-css-prefix}-filter {
      color: $primary-color;
    }
  }

  &-tbody > tr {
    & > td {
      border-top: $border-width-base $border-style-base $border-color-split;
      background-color: $white;

      &:first-child {
        border-left: $border-width-base $border-style-base $border-color-split;
      }

      &:last-child {
        border-right: $border-width-base $border-style-base $border-color-split;
      }
    }

    &:last-child td {
      border-bottom: $border-width-base $border-style-base $border-color-split;
    }

    &.#{$table-prefix-cls}-expanded-row {
      & > td {
        border-top: none;
      }

      & > td[colspan]:not([colspan="1"]) {
        padding: $spacing-m $spacing-m $spacing-m $spacing-s !important;
      }

      &:hover > td {
        background: $table-expanded-row-hover-bg;
      }

      .cx-#{$table-prefix-cls}__no-highlight--hover &:hover > td {
        background: transparent;
      }
    }
  }

  &-thead > tr,
  &-tbody > tr {
    transition: all 0.3s;
    background-color: transparent;

    &.#{$table-prefix-cls}-row-hover,
    &:hover > td {
      background: $table-row-hover-bg;

      .cx-#{$table-prefix-cls}__no-highlight--hover & {
        background: transparent;
      }
    }
  }

  &-thead > tr:hover {
    background: none;
  }

  &-footer {
    padding: $table-padding-vertical $table-padding-horizontal;
    background: $table-header-bg;
    border-radius: 0 0 $border-radius-base $border-radius-base;
    position: relative;
    border-top: $border-width-base $border-style-base $border-color-split;

    &::before {
      content: "";
      height: 1px;
      background: $table-header-bg;
      position: absolute;
      top: -1px;
      width: 100%;
      left: 0;
    }
  }

  &.#{$table-prefix-cls}-bordered &-footer {
    border: $border-width-base $border-style-base $border-color-split;
  }

  &-title {
    padding: $table-padding-vertical 0;
    position: relative;
    top: 1px;
    border-radius: $border-radius-base $border-radius-base 0 0;
  }

  &.#{$table-prefix-cls}-bordered &-title {
    border: $border-width-base $border-style-base $border-color-split;
    padding-left: $table-padding-horizontal;
    padding-right: $table-padding-horizontal;
  }

  &-title + &-content {
    position: relative;
    border-radius: $border-radius-base $border-radius-base 0 0;
    overflow: hidden;

    .#{$table-prefix-cls}-bordered & {
      &,
      table {
        border-radius: 0;
      }
    }
  }

  // https://github.com/ant-design/ant-design/issues/4373
  &-without-column-header &-title + &-content,
  &-without-column-header table {
    border-radius: 0;
  }

  &-tbody > tr.#{$table-prefix-cls}-row-selected td {
    background: $table-selected-row-bg;
  }

  &-thead > tr > th.#{$table-prefix-cls}-column-sort {
    background: $table-header-sort-bg;
  }

  &-thead > tr > th,
  &-tbody > tr > td {
    padding: $table-padding-vertical $table-padding-horizontal;
  }

  &-thead > tr > th.#{$table-prefix-cls}-selection-column-custom {
    padding-left: 16px;
    padding-right: 0;
  }

  &-thead > tr > th.#{$table-prefix-cls}-selection-column,
  &-tbody > tr > td.#{$table-prefix-cls}-selection-column {
    text-align: center;
    min-width: 62px;
    width: 62px;

    .#{$ant-prefix}-radio-wrapper {
      margin-right: 0;
    }

    .#{$ant-prefix}-checkbox-input {
      vertical-align: middle;
    }
  }

  &-expand-icon-th,
  &-row-expand-icon-cell {
    text-align: center;
    min-width: 50px;
    width: 50px;
  }

  &-expand-on-row-click tr {
    cursor: pointer;
  }

  &-header {
    background: $table-header-bg;
    overflow: hidden;
  }

  &-loading {
    position: relative;

    .#{$table-prefix-cls}-body {
      background: $component-background;
      opacity: 0.5;
    }

    .#{$table-prefix-cls}-adjust-hue-holder {
      height: 20px;
      line-height: 20px;
      left: 50%;
      top: 50%;
      margin-left: -30px;
      position: absolute;
    }

    .#{$table-prefix-cls}-with-pagination {
      margin-top: -20px;
    }

    .#{$table-prefix-cls}-without-pagination {
      margin-top: 10px;
    }
  }

  &-column-sorter {
    position: relative;
    margin-left: 8px;
    display: inline-block;
    width: 14px;
    height: 9px;
    text-align: center;
    font-weight: normal;
    color: $table-header-icon-color;

    &-up {
      position: absolute;
      bottom: 50%;
    }

    &-down {
      position: absolute;
      top: 50%;
    }

    &-up,
    &-down {
      display: block;
      width: 14px;
      height: 16px;
      cursor: pointer;

      svg {
        display: none;
      }
      
      &:hover {
        & .#{$iconfont-css-prefix}-caret-up::before,
        & .#{$iconfont-css-prefix}-caret-down::before {
          opacity: $opacity-hover;
        }
      }

      &.on {
        .#{$iconfont-css-prefix}-caret-up::before,
        .#{$iconfont-css-prefix}-caret-down::before {
          border-top: 2px solid $primary-color;
          border-right: 2px solid $primary-color;
        }
      }

      &.off {
        .#{$iconfont-css-prefix}-caret-up::before,
        .#{$iconfont-css-prefix}-caret-down::before {
          border-color: $grey400;
        }
      }
    }

    &-up::after {
      top: -30px;
    }

    &-down::after {
      bottom: -30px;
    }

    .#{$iconfont-css-prefix}-caret {
      &-down::before {
        transform: rotate(135deg);
      }

      &-up::before {
        bottom: 0;
        transform: rotate(-45deg);
      }

      &-up,
      &-down {
        // @include iconfont-size-under-12px(8px);

        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        transition: all 0.3s;

        &::before {
          display: block;
          position: absolute;
          width: 6px;
          height: 6px;
          left: 3px;
          border-top: 1px solid;
          border-right: 1px solid;
          content: "";
        }
      }
    }
  }

  &-placeholder {
    position: relative;
    padding: $table-padding-vertical $table-padding-horizontal;
    background: $component-background;
    text-align: center;
    font-size: $font-size-base;
    color: $text-color-secondary;
    z-index: 1;

    .#{$iconfont-css-prefix} {
      margin-right: 4px;
    }
  }

  &-pagination.#{$ant-prefix}-pagination {
    margin: 16px 0;
    text-align: center;
  }

  &-row {
    &-expand-icon {
      width: 23px;
      height: 100%;
      cursor: pointer;
      display: inline-block;

      &::after {
        display: inline-block;
        width: 12px;
        height: 12px;
        user-select: none;
        border-color: $grey670;
        border-style: solid;
        border-width: 3px 3px 0 0;
        border-radius: 0 4px 0 0;
        content: "";
        position: relative;
        top: -2px;
        transform: rotate(135deg);
      }
    }

    &-expanded::after {
      top: calc(50% - 10px);
      transform: rotate(-45deg);
    }

    &-spaced {
      visibility: hidden;

      &::after {
        content: ".";
      }
    }
  }

  .#{$table-prefix-cls}-row-indent + .#{$table-prefix-cls}-row-expand-icon {
    margin-right: 8px;
  }

  &-scroll {
    overflow: auto;
    overflow-x: hidden;

    table {
      width: auto;
      min-width: 100%;
    }
  }

  &-body-inner {
    height: 100%;
  }

  &-fixed-header > &-content > &-scroll > &-body {
    position: relative;
    background: $component-background;
  }

  &-fixed-header &-body-inner {
    overflow: scroll;
  }

  &-fixed-header &-scroll &-header {
    overflow: scroll;
    padding-bottom: 20px;
    margin-bottom: -20px;
  }

  &-fixed-left,
  &-fixed-right {
    position: absolute;
    top: 0;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    border-radius: 0;

    table {
      width: auto;
      background: $component-background;
    }
  }

  &-fixed-header &-fixed-left &-body-outer &-fixed,
  &-fixed-header &-fixed-right &-body-outer &-fixed {
    border-radius: 0;
  }

  &-fixed-left {
    left: 0;
    box-shadow: 6px 0 6px -4px $shadow-color;

    .#{$table-prefix-cls}-header {
      overflow-y: hidden;
    }
    // hide scrollbar in left fixed columns
    .#{$table-prefix-cls}-body-inner {
      margin-right: -20px;
      padding-right: 20px;
    }

    .#{$table-prefix-cls}-fixed-header & .#{$table-prefix-cls}-body-inner {
      padding-right: 0;
    }

    tr:first-child > td:last-child {
      border-top-right-radius: 0;
    }

    tr:last-child > td:last-child {
      border-bottom-right-radius: 0;
    }
  }

  &-fixed-right {
    right: 0;
    box-shadow: -6px 0 6px -4px $shadow-color;

    // hide expand row content in right-fixed Table
    // https://github.com/ant-design/ant-design/issues/1898
    .#{$table-prefix-cls}-expanded-row {
      color: transparent;
      pointer-events: none;
    }
  }

  &-scroll-position-left &-fixed-left {
    box-shadow: none;
  }

  &-scroll-position-right &-fixed-right {
    box-shadow: none;
  }
}

.#{$table-prefix-cls}-middle {
  > .#{$table-prefix-cls}-title,
  > .#{$table-prefix-cls}-footer {
    padding: $table-padding-vertical * 2/3 $table-padding-horizontal/2;
  }

  > .#{$table-prefix-cls}-content {
    > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-body > table,
    > .#{$table-prefix-cls}-scroll > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-scroll > .#{$table-prefix-cls}-body > table,
    > .#{$table-prefix-cls}-fixed-left > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-fixed-right > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-fixed-left
      > .#{$table-prefix-cls}-body-outer
      > .#{$table-prefix-cls}-body-inner
      > table,
    > .#{$table-prefix-cls}-fixed-right
      > .#{$table-prefix-cls}-body-outer
      > .#{$table-prefix-cls}-body-inner
      > table {
      > .#{$table-prefix-cls}-thead > tr > th,
      > .#{$table-prefix-cls}-tbody > tr > td {
        padding: $table-padding-vertical * 2/3 $table-padding-horizontal/2;
      }
    }
  }
}

.#{$table-prefix-cls}-small {
  > .#{$table-prefix-cls}-title,
  > .#{$table-prefix-cls}-footer {
    padding: $table-padding-vertical/2 $table-padding-horizontal/2;
  }

  > .#{$table-prefix-cls}-title {
    border-bottom: $border-width-base $border-style-base $border-color-split;
    top: 0;
  }

  > .#{$table-prefix-cls}-content {
    > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-body > table,
    > .#{$table-prefix-cls}-scroll > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-scroll > .#{$table-prefix-cls}-body > table,
    > .#{$table-prefix-cls}-fixed-left > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-fixed-right > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-fixed-left
      > .#{$table-prefix-cls}-body-outer
      > .#{$table-prefix-cls}-body-inner
      > table,
    > .#{$table-prefix-cls}-fixed-right
      > .#{$table-prefix-cls}-body-outer
      > .#{$table-prefix-cls}-body-inner
      > table {
      border: 0;

      > .#{$table-prefix-cls}-thead > tr > th,
      > .#{$table-prefix-cls}-tbody > tr > td {
        padding: $table-padding-vertical/2 $table-padding-horizontal/2;
      }

      > .#{$table-prefix-cls}-thead > tr > th {
        background: transparent;
      }
    }

    > .#{$table-prefix-cls}-scroll > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-scroll > .#{$table-prefix-cls}-body > table,
    > .#{$table-prefix-cls}-fixed-left > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-fixed-right > .#{$table-prefix-cls}-header > table,
    > .#{$table-prefix-cls}-fixed-left
      > .#{$table-prefix-cls}-body-outer
      > .#{$table-prefix-cls}-body-inner
      > table,
    > .#{$table-prefix-cls}-fixed-right
      > .#{$table-prefix-cls}-body-outer
      > .#{$table-prefix-cls}-body-inner
      > table {
      padding: 0;
    }

    .#{$table-prefix-cls}-header {
      background: $component-background;
    }
  }

  &.#{$table-prefix-cls}-bordered {
    border-right: 0;

    .#{$table-prefix-cls}-title {
      border: 0;
      border-bottom: $border-width-base $border-style-base $border-color-split;
      border-right: $border-width-base $border-style-base $border-color-split;
    }

    .#{$table-prefix-cls}-footer {
      border: 0;
      border-top: $border-width-base $border-style-base $border-color-split;
      border-right: $border-width-base $border-style-base $border-color-split;

      &::before {
        display: none;
      }
    }

    .#{$table-prefix-cls}-placeholder {
      border-left: 0;
      border-bottom: 0;
    }
  }
}
