@import '../../theme/src/base/_cx-bootstrap-variables.scss';

.cx-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  // global styles
  td,
  th {
    padding: $spacing-xs $spacing-s;
    font-weight: 400;
  }

  // header styles
  thead th {
    color: $gray700;
    font-size: $font-size-small;
  }

  // body styles
  tbody {
    tr {
      border: 1px solid $border-color-default;

      &:hover td,
      &:hover th {
        background-color: $gray300;
      }
    }
  }

  // styles for options
  &.cx--comfortable {
    td,
    th {
      padding: $spacing-s $spacing-m;
    }
  }

  &.cx--bordered {
    td {
      border: 1px solid $border-color-default;
    }
  }

  &.cx--hoverOff {
    tr:hover td,
    tr:hover th {
      background-color: transparent;
    }
  }

  &.cx--striped {
    tr:nth-child(even) {
      background-color: $gray300;

      &:hover td,
      &:hover th {
        background-color: $gray400;
      }
    }
  }

  &.cx-table.cx--striped tr:nth-child(even):hover td,
  &.cx-table.cx--striped tr:nth-child(even):hover th {
    background-color: transparent;
  }
}
