@import '../../theme/src/base/_cx-bootstrap-variables.scss';

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Toastify__toast {
  border: 1px solid $border-color-default;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-deep;
  display: block;
  min-height: inherit;

  &.fadeIn {
    animation-name: fadeIn !important;
  }

  &.fadeOut {
    animation-name: fadeOut !important;
  }
}

.Toastify__toast-body {
  margin: 0;
  font-size: $font-size-base;
  font-family: $font-family-base;
  color: $text-color;

  > div {
    display: flex;
    justify-content: space-between;
  }

  svg {
    min-width: 24px;
  }

  .cx-toast-content-wrapper {
    margin: 2px 0 0 $spacing-xs;
    padding-right: $spacing-xs;
    flex-grow: 1;
    padding-bottom: $spacing-xxs;
  }
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast:hover .icon-close {
  opacity: $opacity-hover;
}

.cx-toast-icon-success {
  color: $brand-success;
}

.cx-toast-icon-warning {
  color: $orange-ext2;

  .IconWarning__exclamation {
    fill: $text-color !important;
  }
}

.cx-toast-icon-error {
  color: $brand-danger;
}

.cx-toast-icon-info {
  color: $brand-info;
}
