@import '../../theme/src/base/_cx-bootstrap-variables.scss';

.#{$bsPrefix}control-label.control-label--required::after {
  display: inline-block;
  content: "*";
  color: $brand-danger;
  margin-left: 4px;
}

.input-side-by-side {
  display: table;
  width: 100%;

  &::after,
  &::before {
    content: "";
    display: table;
    clear: both;
  }

  & > label,
  & > input,
  & > textarea,
  & > select,
  & .date-picker-right-cell {
    display: table-cell;
  }

  & > textarea {
    vertical-align: top; // https://stackoverflow.com/questions/7144843/extra-space-under-textarea-differs-along-browsers
    width: 100% !important;
    min-height: 76px;
  }

  & .#{$bsPrefix}control-label {
    width: 25%;
    padding: 6px 8px 0 0;
    display: table-cell;
    vertical-align: top;
    text-align: right;
  }

  & .#{$bsPrefix}input-group {
    & + .#{$bsPrefix}help-block {
      text-align: left;
    }
  }
}
