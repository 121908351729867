// Pulled styling from
// https://github.com/ant-design/ant-design/blob/master/components/pagination/style/index.less

$pagination-prefix-cls: '#{$ant-prefix}-pagination';

$pagination-item-size: 32px;
$pagination-item-size-sm: 24px;
$border-width-base: 1px;
$border-style-base: solid;
$border-color-base: $grey120;
$primary-5: $cox-blue;
$disabled-color: $grey400;

.#{$pagination-prefix-cls} {
  @include reset-component;

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul li {
    margin-bottom: 0;
  }

  &::after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }

  &-total-text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }

  &-item {
    cursor: pointer;
    user-select: none;
    text-align: center;
    list-style: none;
    display: inline-block;
    vertical-align: middle;
    outline: 0;
    margin: 0 $spacing-xs;

    a {
      text-decoration: none;
      color: $link-color;
      margin: 0 $spacing-xs;
    }

    &:focus,
    &:hover {
      border-color: $primary-color;

      a {
        color: $link-hover-color;
      }
    }

    &-active {
      border-color: $primary-color;
      font-weight: 500;
      background-color: $link-color;
      border-radius: $border-radius-small;

      a {
        color: $white800;
      }

      &:focus,
      &:hover {
        border-color: $primary-5;
      }

      &:focus a,
      &:hover a {
        color: $white800;
      }
    }
  }

  &-jump-prev,
  &-jump-next {
    outline: 0;
    color: $link-color;
  }

  &-jump-prev {
    &:focus,
    &:hover {
      &::after {
        content: '\2026';
      }
    }
  }

  &-jump-next {
    &:focus,
    &:hover {
      &::after {
        content: '\2026';
      }
    }
  }

  &-prev,
  &-next,
  &-jump-prev,
  &-jump-next {
    cursor: pointer;
    border-radius: $border-radius-base;
    list-style: none;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
  }

  &-jump-prev:after,
  &-jump-next:after {
    content: '\2026';
  }

  &-prev,
  &-next {
    outline: 0;

    svg {
      display: block;
    }

    a {
      color: $text-color;
      user-select: none;
      vertical-align: text-top;
    }

    &:hover a {
      color: $link-hover-color;
    }
  }

  &-disabled {
    &,
    &:hover,
    &:focus {
      cursor: not-allowed;

      a,
      .#{$pagination-prefix-cls}-item-link {
        color: $disabled-color;
        cursor: not-allowed;
      }
    }
  }

  &-slash {
    margin: 0 10px 0 5px;
  }

  &-options {
    vertical-align: middle;
    float: right;

    &-size-changer.#{$ant-prefix}-select {
      display: inline-block;
    }

    &-quick-jumper {
      display: inline-block;
      vertical-align: top;
      height: $input-height-base;
      line-height: $input-height-base;

      input {
        margin: 0 8px;
        width: 50px;
      }
    }
  }

  &-simple &-prev,
  &-simple &-next {
    height: $pagination-item-size-sm;
    line-height: $pagination-item-size-sm;
    vertical-align: top;

    .#{$pagination-prefix-cls}-item-link {
      border: 0;
      height: $pagination-item-size-sm;

      &::after {
        height: $pagination-item-size-sm;
        line-height: $pagination-item-size-sm;
      }
    }
  }

  &-simple &-simple-pager {
    display: inline-block;
    margin-right: 8px;
    height: $pagination-item-size-sm;

    input {
      margin-right: 8px;
      box-sizing: border-box;
      background-color: $component-background;
      border-radius: $border-radius-base;
      border: $border-width-base $border-style-base $border-color-base;
      outline: none;
      padding: 0 6px;
      height: 100%;
      text-align: center;

      &:hover {
        border-color: $primary-color;
      }
    }
  }

  &.mini &-total-text,
  &.mini &-simple-pager {
    height: $pagination-item-size-sm;
    line-height: $pagination-item-size-sm;
  }

  &.mini &-item {
    margin: 0 $spacing-xs;
  }

  &.mini &-item:not(&-item-active) {
    background: transparent;
    border-color: transparent;
  }

  &.mini &-prev,
  &.mini &-next {

    svg {
      display: block;
    }
  }

  &.mini &-prev &-item-link,
  &.mini &-next &-item-link {
    border-color: transparent;
    background: transparent;
  }

  &.mini &-jump-prev,
  &.mini &-jump-next {
    margin-bottom: 0;
  }

  &.mini &-options {
    //margin-top: 6px;

    &-quick-jumper {
      input {
        width: 44px;
      }
    }
  }
}

@media only screen and (max-width: $screen-lg) {
  .#{$pagination-prefix-cls}-item {
    &-after-jump-prev,
    &-before-jump-next {
      display: none;
    }
  }
}

@media only screen and (max-width: $screen-sm) {
  .#{$pagination-prefix-cls}-options {
    display: none;
  }
}
